import React, { Component, Fragment } from 'react'
import {Container,Row,Col,Card} from 'react-bootstrap';
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import SwiperCore, { FreeMode, Pagination, Autoplay } from "swiper";

// import required modules
import { Navigation } from "swiper";

import BgSlider1 from '../../assets/images/Study-Medicine-in-Russia-RostSMU.png';



class HomeSlider extends Component {
  
     render() {
      SwiperCore.use([Autoplay]);

    return (
     <Fragment>
		 <div className="rs-slider slider-overlay-2">
  <>
      <Swiper navigation={true} modules={[Navigation]} className="mySwiper"  autoplay={{ delay: 4000 }}>
      
      <SwiperSlide>
       <img className="slide-bg img-fluid" src={BgSlider1} />
           </SwiperSlide>
             
      </Swiper>
    </>
	
	</div>
     </Fragment>
    )
  }
}

export default HomeSlider
