import React, { Component, Fragment } from 'react'
import Logo from '../../assets/images/logo.png';
import {Link} from "react-router-dom";
import { Container, Row, Col } from 'react-bootstrap';

class MainLogo extends Component {
  render() {
    return (
      <Fragment>
         <div className="rs-header-top">
           <Container>
             <Row>
    
            <Col  key={1} xl={6} lg={6} md={6} sm={12} xs={12}>
            <Link href={process.env.PUBLIC_URL + '/'}> <img className="nav-logo img-fluid" src={Logo} /> </Link>
           </Col>

           <Col  key={1} xl={3} lg={3} md={3} sm={6} xs={12}>

<div className="header-contact">
           <div id="info-details" className="widget-text">
                           <i className="fa fa-envelope"></i>
               <div className="info-text">
                   <a href="mailto:contact@rrecrussia.com">
                     <span>Mail Us</span>
                     contact@rrecrussia.com
         </a>
               </div>
           </div>
       </div>

</Col>
           <Col  key={1} xl={3} lg={3} md={3} sm={6} xs={12}>

           <div className="header-contact pull-right">
						            <div id="phone-details" className="widget-text">
						                <i className="fa fa-phone-volume"></i>
						                <div className="info-text">
						                    <a href="tel:+7-9604430042">
						                    	<span>Call Us</span>
                                  +91-7042284508
									          		</a>
						                </div>
						            </div>
						        </div>
          </Col>

             </Row>


           </Container>


</div>
 
      </Fragment>
    )
  }
}

export default MainLogo
