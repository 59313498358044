import React, { Component, Fragment } from 'react'
import {Container,Row,Col,Card, Carousel} from 'react-bootstrap';
import BgSlider from '../../assets/images/BgSlider.webp';



class AdmissionBottom extends Component {
     render() {
     

    return (
     <Fragment>
<div id="rs-calltoaction" className="rs-calltoaction sec-spacer bg4">
            <Container>
                <div className="rs-cta-inner text-center">
                    <div className="sec-title mb-50 text-center">
                        <h2 className="white-color">Be Doctor from world Renowned Medical University</h2>      
 <p className="white-color">Rostov State Medical University is considered among the top 10 universities in Russia. The major reason for this university to be in the top 10 rankings is its exceptional quality standard and highly adept faculty members. University is well-equipped with state of the art medical facilities that help both students and professors to carry out comprehensive research and training activities.
</p>
                    </div>
				    <a className="cta-button" href="http://rostgmu.net/" target="_blank">Know us more</a>
				</div>
        </Container>
            </div>
       
     
 
      </Fragment>
    )
  }
}

export default AdmissionBottom
