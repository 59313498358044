import React, { Component, Fragment } from 'react'
import {Container,Row,Col,Card, Carousel} from 'react-bootstrap';


class InnerBottom extends Component {
     render() {
     

    return (
     <Fragment>
 <div id="rs-events" className="rs-events sec-spacer">

         <Container>
         <div class="sec-title mb-50 text-center">
                    <h2>FAQs for MBBS in Russia</h2>      
                
                </div>
<Row>

<Col className='p-1' key={1} xl={12} lg={12} md={12} sm={12} xs={12}>
  <h3>Toggle title</h3>
<p>No, foreign students are offered direct admission. No entrance exam or language test is required. Some universities 4/5 universities  in whole Russia are accepting admissions on an entrance exam basis i.e Biology, Chemistry or English.</p>
  
<h3>Toggle title</h3>
  <p>Students must have NEET qualification and 50% in PCB in secondary school examination as per NMC. Yes, some universities are accepting above 60-65% in each subject i.e Biology, Chemistry & English. </p>
  <h3>Q- Can students get an education loan to study in Russia?</h3>
  <p>Yes, education loan is easily available. Moreover, Russian universities also provide all essential documents necessary for education loans from public or private banks.</p>
  <h3>Toggle title</h3>
 <p>A consultant for MBBS in Russia must be the official and authorized admission partner of the medical universities in Russia. Check the letter of authorization and contact number of the official representative on the university website before proceeding with the admission procedure.</p>
 
 <h3>Q- What is the minimum and maximum age for the MBBS?</h3>
 <p>The minimum age is 17 years and the maximum age is 25 years.</p>
 
<h3>Q- What is the scope after completing medical education in Russia?</h3>
 <p>Russian medical universities are approved by NMC, WHO and UNESCO. The Degree is also recognized worldwide. Students can work and settle anywhere in the world after passing the Medical Licensing Exam of a particular nation.</p>
 
<h3>Q- What are the challenges for Indian students in Russia?</h3>
 <p>The only challenge in Russian for Indian students is climate. Winters are too cold up to -15 to -25 degrees Celsius and summers are dry up to 25-40 degrees. Adapting to these climatic conditions may take time.</p>
  
  <h3>Q- Is scholarship programs available for all?</h3>
  <p>Students can avail themselves of scholarship benefits while opting for Russian medical universities or during studies as per his/her academic year results. However, to be a deserving candidate the eligibility criteria must be met.</p>
  
  </Col>

 

</Row>
</Container>

       </div>
      </Fragment>
    )
  }
}

export default InnerBottom
