import React, { Component, Fragment } from 'react'
import {Navbar,Container, Row, Col,Button, Nav, NavDropdown} from 'react-bootstrap';
import MainLogo from './MainLogo';

class Menu extends Component {
  render() {
    return (
      <Fragment>
        
<Navbar bg="dark" expand="lg" className='menu-area'>
  <Container>   
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse id="basic-navbar-nav" className="main-menu menu-mobile justify-content-center">
      <Nav className="menu">
        <Nav.Link href={process.env.PUBLIC_URL + '/'}>Home</Nav.Link>
        <Nav.Link href="https://rrecrostov.com/rostov-state-medical-university/" target="_blank">About Us</Nav.Link> 
        <Nav.Link href="https://rrecrostov.com/faculties-fees/" target="_blank">Faculties & Fees</Nav.Link>
        <Nav.Link href="https://rrecrostov.com/" target="_blank">Admission Process</Nav.Link>
       

      </Nav>
    </Navbar.Collapse>
  </Container>
</Navbar>

               </Fragment>
    )
  }
}

export default Menu
