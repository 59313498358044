import React, { Component, Fragment } from 'react'
import { Container, Row, Col } from 'react-bootstrap'

class News extends Component {
    render() {

        return (
            <Fragment>
                <Container fluid={true} className="news">
                    <Container>
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <marquee scrollamount="3" direction="left" onMouseOver="stop()" onMouseOut="start();">	<span className="text-white"><span className="blink-text">Breaking News:</span>  Dear aspirants, Admissions are open for<span className="blink-text"> 2023</span> academic year. Admissions to <span className="blink-text">RostSMU are strictly based on merit. Limited seats available for all faculties</span>. For General Medicine Faculty <span className="blink-text">(MBBS Program)</span> we have complete six years English Medium. Get direct admission through our official representative only.</span></marquee>

                            </Col>
                        </Row>
                    </Container>
                </Container>
            </Fragment>
        )
    }
}
export default News;