import React, { Component, Fragment } from 'react'
import { Col, Container, Row } from 'react-bootstrap'

export class Features extends Component {
  render() {
    return (
      <Fragment>
        <Container className='ptb-50 features'>
          <Row>
            <Col key={1} xl={12} lg={12} md={12} sm={12} xs={12}>
              <h1>Live Your Medical Dreams <br /> Apply with Rostov State Medical University – Admissions 2023</h1>

              <h2>Study Medicine in Rostov State Medical University (RostSMU, RostGMU, РостГМУ)</h2>

              <p>The <a href="https://rrecrostov.com/" target="_blank">Rostov State Medical University</a> is the successor of Russian Warsaw University, evacuated to Rostov-on-Don, and converted into The Don University. The best representatives of the medical schools of Warsaw, Moscow, St. Petersburg, and Kazan were collected on its medical faculty and have founded their own unique <a href="https://thedoctorsiea.in/rostov-state-medical-university/" target="_blank">Rostov medical school</a>, which has become one of the best in Russia.</p>
              <p >In 1930, the Medical Faculty of the <a href="https://rostovstatemedicaluniversity.com/" target="_blank">Rostov State Medical University</a> was reorganized into the Institute of Medicine, which has become the largest medical university in the South of the USSR.</p>
              <p >In the 50s, the Institute has grown significantly, the university became the first category, won the right to conduct the defense of dissertations for awarding the degree of Doctor of Science. It has organized the Evening Department and the Faculty for Advanced Training of physicians.</p>
              <p >In the 60s, there were opened the Central Scientific Research Laboratory, the Preparatory Faculty on Training Foreign Citizens, several new departments, and at the same time there was an improved clinical base.</p>
              <p >In 1994, the Institute was granted University status, and then there were organized several new faculties.</p>
              <p >Today the<a href="https://rrecrostov.com/" target="_blank"> Rostov State Medical University</a> is the largest medical research center in Southern Russia. </p>

              <h3>Student's first choice to study Medicine in Russia: RostSMU. Call +7-960-443-00-42</h3>
              <h2><a href="https://thedoctorsiea.in/rostov-state-medical-university/" target="_blank">Rostov state medical university today</a></h2>
              <p ><a href="https://rostgmu.net/" target="_blank">Rostov State Medical University</a> is the largest basic training and research center in Southern Russia. Over the 85 years of its existence, it has become a diversified complex with 10 faculties, Medical College, Military Training Center, and its own Clinic. Leading and advanced doctors of our country have formed on the basis of the <a href="https://rrecrostov.com/" target="_blank">Rostov State Medical University</a> their major scientific schools, headed by the Honored Workers of Science, Employees of high school, Honored Doctors, Honored Workers of Health and Culture, as well as world-renown scientists.</p>
              <p >Today the <a href="https://rostgmu.net/" target="_blank">Rostov State Medical University</a> operates more than 100 departments. More than six hundred teachers are constantly working on introducing innovations and interactive forms of learning. Among them, there are 212 Doctors of Medical Science, 101 professors, and 624 PhDs. The academicians and professors who are working at our University now continue to develop the best traditions of their predecessors.</p>
              <p >The system of Supplementary Education is divided into several categories. At the University there are training programs for doctors and medical organizations from all over the South of Russia. Every year about 8000 doctors are retraining on the Faculty Training, more than 1000 interns and residents — at the Faculty of Professional Retraining. In addition, the University has a Preparatory Faculty on Training Foreign Citizens, because the Rostov State Medical University is an international medical school that trains representatives of more than a hundred nationalities and ethnic groups from all over the world.</p>
              <p >The scientific work is organized and coordinated by the scientific and research activity and is implemented into the departments and courses. RostSMU has five scientific laboratories, the Vivarium, the Scientific and Research Institute of Immunology. The Central Scientific Research Laboratory (CSRL) functions effectively, where a wide range of biochemical, molecular-genetic, morphological, microbiological studies are carrying out. There are 4 Dissertation Committees for Ph.D. and doctoral dissertation defense. The Rostov State Medical University develops international scientific cooperation with many countries exchanging scientific staff, students, teachers, highly qualified specialists, and participating in international scientific conferences and congresses.</p>
              <p >The Clinic of our University is a multi-field medical institution, which has wide opportunities for modern diagnostic studies and a high level of effective specialized consultative, diagnostic and medical care to the population of the Rostov Region and other territories of the Southern Federal District and the Russian Federation. It is also the largest training base, which has all conditions for the educational process using the advanced forms and methods of training and modern achievements in medical science. In the Clinic, the students acquire the necessary skills and get acquainted with the most modern medical equipment.</p>


              <h3 ><a href="https://thedoctorsiea.in/rostov-state-medical-university/" target="_blank">RostSMU</a>: A University with 130 years of Excellence. Choose the Best for You; Student Medicine in <a href="https://rrecrostov.com/" target="_blank">Rostov State Medical University</a></h3>
              <p >The Clinic of RostSMU includes 20 inpatient units with 675 beds (including 125-day care beds), 2 Departments of Anesthesiology and Resuscitation, Consultative Outpatient Department, a Dental Department, Medical Center, Сenter of Medical Ophthalmology, 13 medical-diagnostic subdivisions, Blood Technology Center. Today the clinic staff consists of 353 physicians, including 11 professors, 28 Doctors of Medical Science and 52 PhDs; more than 30 employees of the university are the main external specialists and experts of the Ministry of Health of the Russian Federation in the Southern Federal District, the Ministry of Health of the Rostov region and the Municipal Council «Health Management in Rostov-on-Don».</p>
              <p >In recent years the centralization and automation of many diagnostic and medical departments of our University allowed us to concentrate and rationally use the staff, medical equipment, improve the quality of work of each structural unit and create a technology of the continuous medical-diagnostic process. The Rectorship puts particular emphasis on the organization of the educational process at the University. Nowadays we implement interactive forms of training and computerization of the educational process. The best professors and doctors of the University have the opportunity to undergo a course of internship in medical clinics and universities in Europe.</p>
              <p >Under the realization of the priority national project «Health», the Administration of the University is actively developing and effectively implementing modern programs of medical-social rehabilitation of children and adolescents in practical healthcare. Under the realization of the educational task of the priority, national projects in the sphere of health care physicians for the Rostov Region and the whole Caucasian region are successfully trained at our University.</p>

              <p >Graduates of the Rostov State Medical University have all the necessary knowledge, skills, and qualifications that enable them to succeed in their future careers. They are in demand in the labor market.</p>
              <p >Today the<a href="https://thedoctorsiea.in/rostov-state-medical-university/" target="_blank"> Rostov State Medical University</a> is the leading Higher Educational Institution of the Rostov region for training medical specialists. It is also the largest educational, scientific and medical center in Southern Russia and is one of the oldest universities in our country. </p>
              <h2 >Why <a href="https://rostgmu.net/" target="_blank">Rostov state medical university</a>?</h2>
              <ul>
                <li >RostSMU is one of the oldest medical universities in Russia after Moscow, St. Petersburg.</li>
                <li >The only university in Rostov-on-Don is recognized by the Ministry of Healthcare &amp; Ministry of Education and Science of the Russian Federation.</li>
                <li >Government-funded University.</li>
                <li >The only university in Rostov-on-Don, recognized by the WHO, listed in the World Directory of Medical Schools (AVICENNA Directory for Medicine).</li>
                <li ><a href="https://thedoctorsiea.in/rostov-state-medical-university/" target="_blank">RostSMU</a> is recognized by leading medical councils viz. EAU, GMC, NMC, ECFMG, etc.</li>
              </ul>


              <h6>Motto: «Время учиться быть профессионалом» &#8220;It&#8217;s a Time to be a Professional&#8221;  (Study Medicine in <a href="https://studymedicineinrostov.com/" target="_blank">Rostov State Medical University</a>, Russia)</h6>
              <h4 >Official Contact Details; For Indian Students: 1800-572-5827 (Toll-free) | For Other Countries: +7-960-443-00-42</h4>

              <h2><a href="https://rrec.com.ru/rostov-state-medical-university-russia/" target="_blank">Rostov State Medical University</a> – Unleashing Great Opportunity to study <a href="https://thedoctorsiea.in/">MBBS in Russia</a> 2023</h2>
              <p >Many medical aspirants, who want to accomplish their undergraduate medical education abroad, are looking for a reliable Medical university in Russia due to its emerging influence and prowess in imparting top-class Medical education. In this regard Rostov State Medical University, Russia comes on the top of the list. It is one of the leading and most successful medical universities in Russia. It has been the center of attraction for both national and international students seeking top-notch Medical programs. It focuses highly on innovative research and investment in sublime facilities for students including exhaustive infrastructure.</p>
              <h3>RostSMU – Quick Fact check </h3>

              <table class="table" cellpadding="8" cellspacing="8">
                <tbody>
                  <tr>
                    <th>Name of the University</th>
                    <th><a href="https://rrec.com.ru/rostov-state-medical-university-russia/" target="_blank">Rostov State Medical University</a></th>
                  </tr>
                  <tr>
                    <td>Location</td>
                    <td>Rostov-on-Don City, South Russia</td>
                  </tr>
                  <tr>
                    <td>Popular Name/Abbrev.</td>
                    <td>RSMU, RostSMU, RostGMU, РостГМУ</td>
                  </tr>
                  <tr>
                    <td>University Type</td>
                    <td>State (Russian Government University)</td>
                  </tr>
                  <tr>
                    <td>Year of Establishment</td>
                    <td>1930</td>
                  </tr>
                  <tr>
                    <td>Number of Faculties</td>
                    <td>6</td>
                  </tr>
                  <tr>
                    <td>Medium of Instruction</td>
                    <td>English/Russian</td>
                  </tr>
                  <tr>
                    <td>NEET Required (For Indian Students Only)</td>
                    <td>Yes, It is Required &amp; Mandatory</td>
                  </tr>
                  <tr>
                    <td>Courses Offered</td>
                    <td><a href="https://rrec.com.ru/rostov-state-medical-university-russia/" target="_blank">General Medicine</a>, Pediatrics, Dentistry, Pharmacy, Preparatory Course</td>
                  </tr>
                  <tr>
                    <td>Cost of Living</td>
                    <td>80-120 USD Dollars</td>
                  </tr>
                  <tr>
                    <td>Minimum percentage required</td>
                    <td>65%  per each subject Chemistry, Biology &amp; English</td>
                  </tr>
                  <tr>
                    <td>IELTS/TOEFL</td>
                    <td>Not Required</td>
                  </tr>
                  <tr>
                    <td>Processing Time for Medical Admission</td>
                    <td>30-35 days Approx.</td>
                  </tr>
                  <tr>
                    <td>Fees for Medicine (MBBS)</td>
                    <td>4250 USD/ (approx)Each Year</td>
                  </tr>
                  <tr>
                    <td>Duration of Medicine</td>
                    <td>6 Years (M.D. Physician)</td>
                  </tr>
                  <tr>
                    <td>University Recognition</td>
                    <td>WHO, NMC &amp; all other major medical councils approved.</td>
                  </tr>
                  <tr>
                    <td>Total Number of Students</td>
                    <td>9000 including 2500+ international Students</td>
                  </tr>
                  <tr>
                    <td>University Ranking</td>
                    <td>Country Ranking – 248 (Sources: 4icu.org)<br />
                      World Ranking – 5344 (Sources: 4icu.org)</td>
                  </tr>
                  <tr>
                    <td>Official Representative</td>
                    <td><a href="https://rrecrostov.com/" target="_blank">www.rrecrostov.com</a> (RREC)</td>
                  </tr>
                  <tr>
                    <td>Category</td>
                    <td><a href="https://rrecrussia.com/" target="_blank">Medical Colleges in Russia</a></td>
                  </tr>
                </tbody>
              </table>

              <h3>Admission Process for <a href="https://thedoctorsiea.in/" target="_blank">study MBBS in Russia</a> in <a href="https://rrecrostov.com/" target="_blank">Rostov State Medical University</a> in 2021</h3>
              <p>Students can apply online for the <a href="https://rostgmu.net/" target="_blank">Rostov State Medical University admission</a> process which is simple and easy. Here is the whole process explained for you:</p>
              <h5>Online Application</h5>
              <p>RSMU offers one intake in a year. The admission form can be availed on <a href="https://rostgmu.net/" target="_blank">Rostov State Medical University&#8217;s official website</a>. You can apply online by filling in all the requisite details in the form.</p>
              <h5>Fill All Mandatory Fields </h5>
              <p>Fill in all mandatory fields carefully to avoid rejection of the application form.</p>
              <h5>Submission Form Review</h5>
              <p>After the successful submission of the form, the university will revert you in 7-8 days.</p>
              <h5>University Admission / Invitation Letter</h5>
              <p>The eligible students when selected for admission will get the admission provisional and invitation letter from the university. Now you can submit <a href="https://rostgmu.net/">Rostov State Medical University fees</a> and get the receipt.</p>
              <h5>Apply for Visa Now</h5>
              <p>After the invitation letter is received from the university, students can apply for Visa. You must contact the Russian Embassy or Consulate and apply for Visa there or our representative RREC Team will help you with the same. </p>
              <h5>Ready to Fly </h5>
              <p>After the Visa formality is done, students will be ready to catch your flight. Before fly to Russia students should once again check all essential documents. <a href="https://rrecrostov.com/" target="_blank">RREC Team</a> wishes you all the best.</p>
              <h2><a href="https://rrecrussia.com/rostov-state-medical-university-russia/" target="_blank">Rostov</a> the beautiful, capital and administrative city of South Russia</h2>

              <h3>Why choose<a href="https://rrecrostov.com/"> RREC</a> for admission to <a href="https://studymedicineinrostov.com/" target="_blank">Rostov State Medical University</a>?</h3>
              <p>Many students are not equipped with all the requisite information that will lead them to successful admission to <a href="https://rostgmu.in/" target="_blank">Rostov State Medical University, Russia</a>. They eventually have to face rejection which is heartbreaking for them. Moreover, they also unaware of false and enticing promises made by unauthorized agents. Under such circumstances, holding hands with a leading and trusted counselor like <a href="https://rrecrostov.com/" target="_blank">RREC</a> is extremely crucial. We capitalize on imparting comprehensive information through counseling, seminars, group invitation, and so on. Parents and students are always welcome to contact our official representative regarding any sort of <a href="https://studymedicineinrostov.com/" target="_blank">study Medicine in Russia-Rostov State Medical University</a> queries. <a href="https://rrecrostov.com/" target="_blank">Reliable Russian Educational Consultants (RREC)</a> has been guiding young minds and talent as an official representative for RostSMU and several other prominent medical universities in Russia for years. </p>

            </Col>
          </Row>
        </Container>

      </Fragment>
    )
  }
}

export default Features
