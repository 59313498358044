import React, { Component, Fragment } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Socialmedia from './Socialmedia'


class Footer extends Component {
  render() {
    return (
      <Fragment>
        <Container className='text-center footer' fluid={true}>
          <Row>
            <Col key={1} xl={12} lg={12} md={12} sm={12} xs={12}>
              <p>Copyright © 2023 <a href="https://studymedicineinrostov.com/">Study Medicine in RostSMU</a>. Presented by <a target="_blank" href="https://rrecrostov.com">RREC Team</a></p>
            </Col>
          </Row>
        </Container>

        <Socialmedia />
      </Fragment>



    )
  }
}

export default Footer
